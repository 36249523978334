import VazirTTF from './fonts/vazir/Vazir.ttf';
import VazirEOT from './fonts/vazir/Vazir.eot';
import VazirWOFF from './fonts/vazir/Vazir.woff';
import VazirWOFF2 from './fonts/vazir/Vazir.woff2';
import MediumVazirTTF from './fonts/vazir/Vazir-Medium.ttf';
import MediumVazirEOT from './fonts/vazir/Vazir-Medium.eot';
import MediumVazirWOFF from './fonts/vazir/Vazir-Medium.woff';
import MediumVazirWOFF2 from './fonts/vazir/Vazir-Medium.woff2';
import BoldVazirTTF from './fonts/vazir/Vazir-Bold.ttf';
import BoldVazirEOT from './fonts/vazir/Vazir-Bold.eot';
import BoldVazirWOFF from './fonts/vazir/Vazir-Bold.woff';
import BoldVazirWOFF2 from './fonts/vazir/Vazir-Bold.woff2';
import SahelBoldTTF from './fonts/sahel/Sahel-Bold.ttf';
import SahelBoldEOT from './fonts/sahel/Sahel-Bold.eot';
import SahelBoldWOFF from './fonts/sahel/Sahel-Bold.woff';
import SahelBoldWOFF2 from './fonts/sahel/Sahel-Bold.woff2';

export default {
    VazirTTF,
    VazirEOT,
    VazirWOFF,
    VazirWOFF2,
    MediumVazirTTF,
    MediumVazirEOT,
    MediumVazirWOFF,
    MediumVazirWOFF2,
    BoldVazirTTF,
    BoldVazirEOT,
    BoldVazirWOFF,
    BoldVazirWOFF2,
    SahelBoldTTF,
    SahelBoldEOT,
    SahelBoldWOFF,
    SahelBoldWOFF2 
}