import { createGlobalStyle } from 'styled-components';
import fontFiles from './fonts';

export default createGlobalStyle `
    @font-face {
        font-family: blog_font;
        font-display: auto;
        src: url('${fontFiles.VazirTTF}') format('ttf'),
            url('${fontFiles.VazirWOFF}') format('woff'),
            url('${fontFiles.VazirWOFF2}') format('worff2'),
            url('${fontFiles.VazirEOT}') format('eot')
        ;
        font-weight: 400;
    }
    @font-face {
        font-family: blog_font;
        font-display: auto;
        src: url('${fontFiles.BoldVazirTTF}') format('ttf'),
            url('${fontFiles.BoldVazirWOFF}') format('woff'),
            url('${fontFiles.BoldVazirWOFF2}') format('worff2'),
            url('${fontFiles.BoldVazirEOT}') format('eot')
        ;
        font-weight: 800;
    }
    @font-face {
        font-family: blog_font;
        font-display: auto;
        src: url('${fontFiles.MediumVazirTTF}') format('ttf'),
            url('${fontFiles.MediumVazirWOFF}') format('woff'),
            url('${fontFiles.MediumVazirWOFF2}') format('worff2'),
            url('${fontFiles.MediumVazirEOT}') format('eot')
        ;
        font-weight: 500;
    }
    @font-face {
        font-family: sahel;
        font-display: auto;
        src: url('${fontFiles.SahelBoldTTF}') format('ttf'),
            url('${fontFiles.SahelBoldWOFF}') format('woff'),
            url('${fontFiles.SahelBoldWOFF2}') format('worff2'),
            url('${fontFiles.SahelBoldEOT}') format('eot')
        ;
        font-weight: 500;
    }         
`