import React, { Component } from 'react';
// import Header from './Header';
// import FooterBox from './FooterBox';
import GlobalStyles from '../styles/globalStyles';
import Footer from './Footer';
import About from './About';
import FooterCopyRight from './FooterCopyRight';
import HeaderMetaTags from './HeaderMetaTags';

class Layout extends Component {
    render() {
        const { about } = this.props;
        return (
            <div id="layout" className="container">
                <HeaderMetaTags />
                <GlobalStyles />
                {/* <Header /> */}
                { this.props.children }
                {/* { newsletter === "true" && (
                    <FooterBox />
                )} */}
                <div className="row my-md-4 py-md-4"></div>
                {/* { about === "true" && (
                    <About/>
                )} */}
                <Footer/>
                <div className="row my-md-4"></div>
                <FooterCopyRight />
            </div>
        )
    }
}

export default Layout;