import React, { Component } from 'react';

class SocialNetworkBox extends Component {
    render() {
        return (
            <div id="SN" className="col-12 my-4" aria-hidden={true}>
                <a rel="noreferrer noopener" href="https://twitter.com/FilterShekanha/" className="SN twitter" alt="فیلترشکن‌ها در توییتر">
                    <span>فیلترشکن‌ها در توییتر</span>
                </a>
                <a rel="noreferrer noopener" href="https://t.me/Filtershekanha" className="SN telegram" alt="فیلترشکن‌ها در تلگرام">
                    <span>فیلترشکن‌ها در تلگرام</span>
                </a>
                {/* <a rel="noreferrer noopener" href="https://github.com/filtershekanha" className="SN github" alt="فیلترشکن‌ها در گیت‌هاب">
                    <span>فیلترشکن‌ها در گیت‌هاب</span>
                </a>  
                <a rel="noreferrer noopener" href="/rss.xml" className="SN rss" alt="فید اخبار فیلترشکن‌ها">
                    <span>فید اخبار فیلترشکن‌ها</span>
                </a>                                */}
            </div>
        )
    }
}

export default SocialNetworkBox;