import React, { Component } from 'react';
import SocialNetworkBox from './SocialNetworkBox';
import { Link } from 'gatsby';

class Footer extends Component {
    render() {
        return (
            <div id="footer" className="row justify-content-md-center my-5">
                <div className="col-12 col-md-8 my-5">
                    <hr />
                    <div className="row">
                        <div className="col-12 footer-link text-center">
                            <Link className="c-b" to="/about">درباره ما</Link>&nbsp;
                            {/* <Link className="c-b" to="/contact">تماس با ما</Link>&nbsp; */}
                            <Link className="c-b" to="/privacy">حریم خصوصی</Link>&nbsp;
                            <Link className="c-b" to="/terms">شرایط استفاده</Link>&nbsp;
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="col-12 col-md-8 text-center">
                    <div className="row">
                        <SocialNetworkBox />
                    </div>
                </div>             
            </div>
        );
    }
}

export default Footer;