import React, { Component } from 'react';
import logo from '../styles/img/logo-footer.png';

class About extends Component {
    render() {
        return (
            <div id="footer" className="row justify-content-md-center my-5">
                <div className="col-12 col-md-8 dir-rtl text-center">
                    <img src={ logo } width="125" height="125" alt="تیم فیلترشکن‌ها" />
                    <div className="col-12 px-0 my-4">
                        
                        <span className="font-weight-bold h5 mx-4 c-a">تیم فیلترشکن‌ها</span>
                    </div>
                    <p className="text-center c-b my-5">سانسور و فیلترینگ اینترنتی، یک معضل عمومی در ایران بوده و برای مقابله با آن، تیم فیلترشکن‌ها سال‌هاست که در کنار شماست. ما از طریق وب‌سایت فیلترشکن‌ها تلاش می‌کنیم تا ابزارها و راه‌حل‌هایی را جهت سهولت دور زدن فیلترینگ اینترنتی برای شما فراهم کنیم.
                    </p>
                </div>            
            </div>
        );
    }
}


export default About;