import React, { Component } from 'react';

class FooterCopyRight extends Component {
    render() {
        return (            
            <div className="row my-5">
                <div className="col-12">
                    <p className="text-center footer-note">کلیه حقوق این سایت محفوظ است. <br/>گروه فیلترشکن‌ها - ۲۰۲۱</p>
                </div>
            </div>
        )
    }
}

export default FooterCopyRight;