import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class HeaderMetaTags extends Component {
    render() {
        return (
            <Helmet>
                <html lang="fa-IR" dir="rtl" />       
                <meta charSet="utf-8" />
                <title>فیلترشکن‌ها - دانلود فیلترشکن و اخبار فیلترینگ و سانسور در ایران</title>
                <meta name="description" content="سانسور و فیلترینگ اینترنتی، یک معضل عمومی در ایران بوده و برای مقابله با آن، تیم فیلترشکن‌ها سال‌هاست که در کنار شماست. ما از طریق وب‌سایت فیلترشکن‌ها تلاش می‌کنیم تا ابزارها و راه‌حل‌هایی را جهت سهولت دور زدن فیلترینگ اینترنتی برای شما فراهم کنیم." />
                <meta name="viewport" content="width=device-width, initial-scale=0.9, shrink-to-fit=no" />
                <meta name="google" content="notranslate" />
                <meta name="robots" content="index,follow" />
                <meta name="GOOGLEBOT" content="INDEX,FOLLOW" />
                <meta name="referrer" content="no-referrer" />
                <meta name="twitter:creator" content="@FilterShekanha" />
                <meta name="twitter:site" content="@FilterShekanha" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content={`https://filtershekanha.com/`} />
                <meta name="twitter:image" content="/assets/cover.jpg" />
                <meta property="og:site_name" content="فیلترشکن‌ها" />
                <meta property="og:url" content={`https://filtershekanha.com/`} />
                <meta property="og:title" content="فیلترشکن‌ها" />
                <meta property="og:description" content="وب‌سایت فیلترشکن‌ها" />
                <meta property="og:image" content="/assets/cover.jpg" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3ba5ff" />
                <meta name="apple-mobile-web-app-title" content="Filtershekanha" />
                <meta name="application-name" content="Filtershekanha" />
                <meta name="msapplication-TileColor" content="#2b5797" />
                <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
                <meta name="theme-color" content="#cae2ff" />                          
            </Helmet>
        )
    }
}

export default HeaderMetaTags;